import { Box, Button, Divider, Grid, Slide, Typography, dialogClasses, dialogContentClasses } from "@mui/material";
import { SimpleDialogButton, SimpleDialogProps } from "components/dialog/SimpleDialog"
import { FormContainerFunnelCenter } from "modules/forms/FormContainerFunnelCenter";
import { FormSimple } from "modules/forms/FormSimple";
import { useFormSubmit } from "modules/forms/useFormSubmit"
import { WaitListForm } from "modules/picasso-ui/form/WaitlistForm"
import { IconRenderer } from "modules/picasso-ui/specials/icons/IconRenderer";
import { useAnalytics } from "modules/yoio/analytics/useAnalytics";
import { forwardRef, FunctionComponent, ReactNode, useState } from "react"
import { isString } from "utils/stringUtils";

const Transition = forwardRef(function Transition(props, ref) {
    //@ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
})

export type LandingFormDialogButtonProps = {
    form: string | any
    meta: any
    initialData: any
    originPage?: string
    buttonText?: string
    toggleButtonProps?: any
    renderToggleButton: (onClick:(event:React.MouseEvent<HTMLElement>)=>void, text: string) => ReactNode
    dialog?: LandingFormDialogProps
}

export type LandingFormDialogProps = {
    incentiveLabel?: string
    incentiveTitle?: string
    incentiveSubtitle?: string
    fullScreen?: boolean
    formContainer?: 'standard' | 'funnelCenter'
}

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

export const LandingFormDialogButton: FunctionComponent<LandingFormDialogButtonProps> = ({form, meta, initialData, originPage, renderToggleButton, dialog = {}, buttonText, toggleButtonProps}) => {

    const { trackEvent } = useAnalytics()

    let display = "waitlist"

    if (form && !isString(form) && form?.formioConfig) {
        display = "formsimple"
    }

    dialog.formContainer = dialog.formContainer ?? 'standard'

    const getDialogProps = (formContainer: 'standard' | 'funnelCenter') => {
        if (formContainer === 'standard') {
            return {
                maxWidth: 'sm',
                fullWidth: true,
                positionTop: true,
                incentiveTitle: dialog.formContainer === 'standard' ? dialog?.incentiveTitle : null,
                incentiveSubtitle: dialog?.incentiveSubtitle,
            }
        }
        else if (formContainer === 'funnelCenter') {
            return {
                fullScreen: true,
                fullWidth: true,
                showCloseButton: true,
                sx: {
                    [`& .${dialogClasses.paper}`]: {
                        borderRadius: '0 !important',
                        padding: '0px',
                    },
                    [`& .${dialogContentClasses.root}`]: {
                        padding: '0px',
                    },
                },
            }
        } 
        return {}
    }

    const dialogProps = {
        hideActions: true,
        TransitionComponent: Transition,
        ...getDialogProps(dialog.formContainer)
    } 

    const [key, setKey] = useState(1)
    
    const { submitForm } = useFormSubmit({
        form,
        onSuccess: () => {
            //clear
            setKey(key=>key+1)
            return Promise.resolve()
        },
        onError: (data)=> {

        }
    })

    const handleSubmit = (data) => {
        data.originPage = originPage
        submitForm(data, undefined)
    }

    //const formEffective = {...form} 

    const renderToggleButtonDefault = (onClick, text) => {

        let { buttonProps, startIcon, endIcon, ...otherProps} = toggleButtonProps

        if (endIcon && typeof endIcon === 'string' && endIcon.startsWith('muiicon:')) {
            endIcon = <IconRenderer name={endIcon} />
        }

        if (startIcon && typeof startIcon === 'string' && startIcon.startsWith('muiicon:')) {
            startIcon = <IconRenderer name={startIcon} />
        }

        return <Button onClick={onClick} {...buttonProps} {...otherProps} startIcon={startIcon} endIcon={endIcon}>{text}</Button>
    }

    const renderForm = () => {

        const logLevel = environment === 'development' ? 'info' : null

        if (meta) {
            initialData = initialData??{}
            initialData = { meta, ...initialData }
        }

        return <>
            {display === "waitlist" &&
                <>
                    {/** @ts-ignore */}
                    <WaitListForm key={key} onSubmit={handleSubmit} submitButtonText={dialog?.submitButtonText}/>
                </>
            }
            {display === "formsimple" &&
                    <FormSimple form={form} initialData={initialData} logLevel={logLevel} />
            }
        </>
    }
     
    return (
        <>
            {/** @ts-ignore */}
            <SimpleDialogButton 
                toggleButtonText={buttonText || "Sign up"}
                dialogProps={dialogProps}
                renderToggleButton={renderToggleButton || renderToggleButtonDefault}
                toggleButtonProps={toggleButtonProps}
                onBeforeOpen={()=>{
                    if (meta?.mediacode) {
                        trackEvent({action: 'formDialogButtonClicked_'+meta.mediacode})
                    }
                }}
                >
                {dialog.formContainer === 'standard' && <Divider />}
                <Box sx={{
                    '& button': {
                        fontWeight: '400 !important'
                    }
                }}>
                    {dialog.formContainer === 'standard' &&
                        <Box mt={4} pb={2}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} md={8}>
                                    {renderForm()}
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {dialog.formContainer === 'funnelCenter' &&
                        <FormContainerFunnelCenter maxWidth="sm" sx={{ height: '100vh', minHeight: '100vh', display: 'flex', flexDirection: 'column', pt: 6}}>
                            {dialog.incentiveLabel && <Typography mb={6} color="primary" fontWeight="600" textTransform="uppercase">{dialog.incentiveLabel}</Typography> }
                            <Typography variant="h2" mb={1}>{dialog.incentiveTitle}</Typography>
                            {renderForm()}
                        </FormContainerFunnelCenter>
                    }
                </Box>
            </SimpleDialogButton>
        </>
    )

}