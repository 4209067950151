import { Box, Button, TextField, inputBaseClasses } from "@mui/material";
import { useState } from "react"
import { isStringEmail } from "utils/stringUtils"

export const WaitListForm = ({defaultValue, onSubmit, submitButtonText}) => {

    const [email, setEmail] = useState(defaultValue || '')
    const [errors, setErrors] = useState([])

    const handleChange = (e) => {
        const updated = e.target.value
        setEmail(updated)
        if (updated.length > 0) {
            setErrors([])
        }
    }

    const handleSubmit = () => {
        if (email.length === 0 || !isStringEmail(email)) {
            setErrors(['Enter valid email'])
            return;
        }
        onSubmit({
            email
        })
    }

    return (
        <>
            <Box>
                <TextField 
                    placeholder="name@example.com"
                    type="text"
                    variant="outlined"
                    fullWidth={true}
                    defaultValue={email}
                    onChange={handleChange}
                    required={true}
                    error={errors.length > 0}
                    helperText={errors[0]}
                    sx={{
                        [`& .${inputBaseClasses.input}`]: {
                            fontSize: '16px',
                        }
                    }}
                />
            </Box>
            <Box mt={3} pb={0}>
                <Button
                    color="primary" 
                    variant="contained" 
                    fullWidth
                    size="extraLarge"
                    onClick={handleSubmit}
                >
                    {submitButtonText}
                </Button>
{/*                 <Box textAlign="center" color="text.secondary" pt={2} pb={2}>
                    <Typography>We will notify you as soon as you can sign up.</Typography>
                </Box> */}
            </Box>
        </>
    )
    
}