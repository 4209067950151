import { useTheme } from "@emotion/react"
import { Box, Breakpoint, Container, ContainerProps, useMediaQuery } from "@mui/material"
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox"
import { FC, ReactNode } from "react"

export const FormContainerFunnelCenter: FC<ContainerProps & { secondaryContent?: ReactNode, secondaryContentUp?: Breakpoint }> = ({secondaryContent, secondaryContentUp = 'lg', children, ...props}) => {

    let sx = (Array.isArray(props.sx) ? props.sx : [props.sx]) as any[]
    sx.push({pb: 4, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 14px 0px'})

    const theme = useTheme()
    const largeScreen = useMediaQuery(theme.breakpoints.up(secondaryContentUp));

    if (secondaryContent && largeScreen) {
        return <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={[...sx, theme=>({ maxWidth: theme.breakpoints.values.sm })]}>{children}</Box>
            <VerticalAlignBox sx={theme=>({ maxWidth: theme.breakpoints.values.sm })}>{secondaryContent}</VerticalAlignBox>
        </Box>
    }

    return <Container {...props} sx={sx}>{children}</Container>
}